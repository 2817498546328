import util, { getCurrency } from "../Utils/utils";
import moment from "moment";
import axios from "axios";
import { getQueryData } from "../Helper/helper";
import { notification } from "../Shared/NotificationModal";

export default class FlightService {
  searchFlight(body) {
    const queryData = getQueryData(window.location.search);
    const cabin = queryData && queryData.cabin ? Number(queryData.cabin) : 4;
    const utmCampaign =
      queryData && queryData.utm_campaign
        ? queryData.utm_campaign
        : "localSearch";
    let params = { ...body };
    console.log(params);
    params.arrival = params.arrival
      ? moment(params.arrival).format("YYYY-MM-DD")
      : "";
    params.depart = moment(params.depart).format("YYYY-MM-DD");
    params.to = params.to;
    params.from = params.from;
    params.cabin = cabin;
    params.utm_campaign = utmCampaign;
    params.currency = getCurrency();
    return util
      .sendApiRequest("/flight/search", "POST", true, params)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  //get flight offers
  searchFlightOffers(body) {
    let params = { ...body };
    params.arrival = moment(params.arrival).format("YYYY-MM-DD");
    params.depart = moment(params.depart).format("YYYY-MM-DD");
    params.to = params.to;
    params.from = params.from;
    // return util
    //   .sendApiRequest("/flight/search/offers", "POST", true, params)
    //   .then((response) => {
    //     return response;
    return new Promise(async (resolve, reject) => { })
      .then(() => {
        return null;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getAirLineCode(body) {
    return util
      .sendApiRequest("/flight/airlinecode", "POST", true, body)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getActualUrl(body) {
    return util
      .sendApiRequest("/url/get-actual-url", "POST", true, body)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getAirportCode(airportCodeQuery) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `//autocomplete.travelpayouts.com/jravia?locale=en&with_countries=false&q=${airportCodeQuery}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
        });
    });
  }
  ListgetAirportCode(list) {
    return util
      .sendApiRequest("/flight/airportcode/", "POST", true, list)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getGoogleFlightDetails(data) {
    return util
      .sendApiRequest("/flight/get-google-adv-result", "POST", true, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err)
        // notification(
        //   "error",
        //   "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!"
        // );
      });
  }

  getFlightDetails(data) {
    return util
      .sendApiRequest("/flight/get-flight-details", "POST", true, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  bookFlight(data) {
    return util
      .sendApiRequest("/flight/make-agent-booking", "POST", true, data)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  payment(order) {
    return util
      .sendApiRequest("/flight/payment/", "POST", true, order)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }
  bookingHistory() {
    return util
      .sendApiRequest("/flight/bookinghistory/", "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }
  bookingHistoryDetail(id) {
    return util
      .sendApiRequest("/flight/bookinghistorydetail/" + id, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getFlightAirRule(body) {
    return util
      .sendApiRequest("/flight/get-rules/", "POST", true, body)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }

  getCampaignDetails(utm_campaign) {
    return util
      .sendApiRequest("/campaign/" + utm_campaign, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        notification("error", "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!");
      });
  }
}
